@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&family=Roboto+Mono&display=swap');

/* Reset browser defaults */
button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.zld-preview {
    border: 1px solid #ddd;
}

.zld-canvas-container,
.zld-preview-container {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 1rem;
}

.zld-canvas-wrapper {
    max-width: 100%;
    overflow-x: scroll;
}

/* No focus styles */
#zld-canvas:focus-visible {
    outline: none;
}

/* Control Panel */
.zld-control-button {
    display: inline-block;
    border: 1px solid #000;
    border-radius: .25rem;
    padding: 4px;
    margin: 0 5px;
}

.zld-control-button.inactive {
    border: 1px solid #ccc;
}

/* Drag & Drop */
.zld-drag-over {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    border: 3px dashed #555555;
    z-index: 2;
}

.zld-drag-over .zld-drag-over-content {
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
    width: 100%;
    text-align: center;
}

/* Navbar logo image */
.navbar-brand img {
    /* Add rounding for image */
    border-radius: .25rem;
}

/* Konva text editing */
.zld-canvas-textarea {
    position: absolute;
    border: 1px solid #dddddd;
    padding: 2px;
    margin: 0px;
    overflow: hidden;
    background: none;
    outline: none;
    resize: none;
    transform-origin: left top;
}

/* Width */
.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}